:deep(.ant-tabs-bar) {
  padding: 0 0.4rem;
}

:deep(.ant-tabs-bar) {
  border-bottom: 0 !important;
}

:deep(.ant-tabs-bar) .ant-tabs-tab {
  padding: 0 0.22rem;
  line-height: 0.4rem;
  font-size: 0.18rem;
  color: #888888;
  margin-right: 0.08rem;
  position: relative;
  z-index: 2;
}

:deep(.ant-tabs-bar) .ant-tabs-tab:hover {
  color: #888888;
}

:deep(.ant-tabs-bar) .ant-tabs-tab-active {
  color: #fff !important;
}

:deep(.ant-tabs-bar) .ant-tabs-ink-bar {
  height: 0.4rem;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

:deep(.ant-tabs-tabpane) .ant-spin-container {
  padding: 0 0.4rem;
}

:deep(.table) {
  width: 100%;
}

:deep(.table) .table-thead {
  background-color: #eee;
  border-top: 0.01rem solid #e5e5e5;
  border-bottom: 0.01rem solid #e5e5e5;
}

:deep(.table) .table-thead th {
  font-size: 0.18rem;
  color: #333333;
  font-weight: bold;
  text-align: center;
  height: 0.6rem;
}

:deep(.table) .table-body tr {
  border-bottom: 0.01rem solid #e5e5e5;
}

:deep(.table) .index {
  text-align: center;
  font-size: 0.16rem;
  color: #888888;
  line-height: 1.5;
}

:deep(.table) .cate {
  padding-left: 0.5rem;
}

:deep(.table) .readTime {
  text-align: center;
  color: #888888;
}

:deep(.table) .handle {
  text-align: center;
}

:deep(.table) td {
  padding: 0.1rem 0.2rem;
  height: 0.8rem;
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.5;
}

:deep(.table) .handle_inner {
  display: flex;
  justify-content: center;
}

:deep(.table) .handle_inner span {
  padding: 0 0.15rem;
  white-space: nowrap;
  cursor: pointer;
  color: #888888;
  line-height: 1;
}

:deep(.table) .handle_inner span:first-of-type {
  color: #f6a75c;
  border-left: 0 !important;
}

:deep(.table) .handle_inner span:last-of-type {
  border-left: 0.01rem solid #e5e5e5;
}

:deep(.table) .handle_inner span:hover {
  color: #f6a75c;
}

:deep(.pagenation) {
  margin-top: 0.25rem;
  margin-left: -0.4rem;
  padding-top: 0.5rem;
}

:deep(.ant-tabs-content),
:deep(.model),
:deep(.ant-tabs),
:deep(.browse) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

:deep(.ant-tabs-bar) {
  flex-shrink: 0;
}

:deep(.ant-tabs-content)::before {
  display: none;
}

:deep(.ant-tabs-content) .ant-tabs-tabpane {
  display: flex;
  flex-direction: column;
}

:deep(.ant-tabs-content) .ant-tabs-tabpane-active {
  flex: 1;
}

:deep(.ant-spin-nested-loading) {
  flex: 1;
}

:deep(.ant-empty) {
  min-height: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.breadcrumb {
  border-bottom: 0;
}

.personal {
  display: flex;
  background-color: #f8f8f8;
  padding: 0.6rem;
  margin-bottom: 0.8rem;
}

.personal .left {
  width: 2.6rem;
  padding-right: 0.4rem;
  margin-right: 0.2rem;
  border-right: 0.01rem solid #dee2e8;
}

.personal .right {
  flex: 1;
  padding: 0.05rem 0.4rem 0.5rem;
}

.haspagi {
  padding: 0.05rem 0 0 !important;
  display: flex;
  flex-direction: column;
}

.haspagi :deep(.title) {
  padding-top: 0;
  margin: 0 0.4rem;
  flex-shrink: 0;
}

h1 {
  margin-bottom: 0.3rem;
}

h1 .cn {
  font-size: 0.28rem;
  color: #000f23;
  font-weight: bold;
}

h1 .en {
  font-size: 0.16rem;
  color: #888888;
  font-weight: normal;
}