.ant-menu {
  background-color: transparent;
}

:deep(.ant-menu-root) {
  border-right: 0;
}

:deep(.ant-menu-root) > li {
  margin-bottom: 1PX;
  padding: 0 !important;
  background-color: transparent;
}

:deep(.ant-menu-root) > li .title {
  font-size: 0.16rem;
}

:deep(.ant-menu-root) > li.ant-menu-item-selected {
  border-bottom: 0.01rem solid #000f23 !important;
}

:deep(.ant-menu-root) .ant-menu-submenu-title {
  margin: 0;
  line-height: 0.6rem !important;
  height: 0.6rem !important;
  font-size: 0.18rem;
  color: #000000;
}

:deep(.ant-menu-root) .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: .1rem;
}

:deep(.ant-menu-root) .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
:deep(.ant-menu-root) .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #f6a75c, #f6a75c);
}

:deep(.ant-menu-root) .ant-menu-sub {
  padding: 0.17rem 0;
}

:deep(.ant-menu-root) .ant-menu-item {
  margin: 0 !important;
  font-size: 0.16rem;
  margin-bottom: 1PX !important;
  color: #555555;
}

:deep(.ant-menu-root) .ant-menu-item .subtitle {
  display: block;
  position: relative;
  font-size: 0.16rem;
}

:deep(.ant-menu-root) .ant-menu-item .subtitle i {
  position: absolute;
  top: 50%;
  right: .1rem;
  width: 0.1rem;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateY(-0.02rem);
  opacity: 0;
}

:deep(.ant-menu-root) .ant-menu-item .subtitle i::before {
  position: absolute;
  width: 0.06rem;
  height: 0.015rem;
  background: #fff;
  background: rgba(0, 0, 0, 0.65);
  background-image: linear-gradient(to right, #000f23, #000f23);
  border-radius: 0.02rem;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  transform: rotate(45deg) translateY(-0.022rem);
}

:deep(.ant-menu-root) .ant-menu-item .subtitle i:after {
  position: absolute;
  width: 0.06rem;
  height: 0.015rem;
  background: #fff;
  background: rgba(0, 0, 0, 0.65);
  background-image: linear-gradient(to right, #000f23, #000f23);
  border-radius: 0.02rem;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  transform: rotate(-45deg) translateY(0.022rem);
}

:deep(.ant-menu-root) .ant-menu-item-selected {
  background-color: transparent !important;
  color: #000f23 !important;
}

:deep(.ant-menu-root) .ant-menu-item-selected::after {
  display: none;
}

:deep(.ant-menu-root) .ant-menu-item-selected .subtitle i {
  opacity: 1;
}

:deep(.ant-menu-root) > .ant-menu-item {
  margin: 0;
  line-height: 0.6rem !important;
  height: 0.6rem !important;
  font-size: 0.18rem;
  color: #000000;
}

.default :deep(.ant-menu-submenu-title) {
  background-color: #dce8f3;
}

.default :deep(.ant-menu-sub) {
  background-color: #f4f7fa;
}

.default :deep(.ant-menu-root) > li {
  background-color: #dce8f3;
}

.default :deep(.ant-menu-root) > .ant-menu-item-selected {
  background-color: #dce8f3 !important;
}

.green :deep(.ant-menu-root) > li {
  margin-bottom: 0;
  border-bottom: 1PX solid #dee2e8;
}

.green :deep(.ant-menu-submenu-title) {
  padding: 0 !important;
}

.green :deep(.ant-menu-sub) {
  border-top: 0.01rem solid #000f23;
  background-color: transparent;
}

.green :deep(.ant-menu-sub) .ant-menu-item {
  line-height: 0.4rem;
  height: 0.4rem;
  font-size: 0.14rem;
  padding: 0 !important;
}

.green :deep(.ant-menu-sub) .ant-menu-item .subtitle {
  position: relative;
  color: #000;
}

.green :deep(.ant-menu-sub) .ant-menu-item .subtitle i {
  display: none;
}

.green :deep(.ant-menu-sub) .ant-menu-item.ant-menu-item-selected .subtitle {
  color: #f6a75c;
}

.green :deep(.ant-menu-sub) .ant-menu-item.ant-menu-item-selected .subtitle::before {
  background-color: #000;
}

.green :deep(.ant-menu-submenu-selected),
.green :deep(.ant-menu-submenu-open) {
  background-color: #f8f8f8;
}

.green :deep(.ant-menu-submenu-selected) .title,
.green :deep(.ant-menu-submenu-open) .title {
  color: #000f23;
  font-weight: bold;
}